import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import HomeLayoutAfterLogin from "src/layouts/HomeLayoutAfterLogin";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/shop",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Shop/Index")),
  },
  {
    exact: true,
    path: "/search",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Shop/Search")),
  },
  {
    exact: true,
    path: "/category",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Category/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/payment",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Payment/Payment")),
  },
  {
    exact: true,
    path: "/offers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Offers/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/checkout",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Payment/Checkout")),
  },

  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/ContactUs")),
  },

  {
    exact: true,
    path: "/testimonial",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Testimonial/Testimonial")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/deleteaddress",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Payment/DeleteAddress")),
  },
  {
    exact: true,
    path: "/termsCondition",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Terms/TermsCondition")),
  },
  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Terms/AboutUs")),
  },
  {
    exact: true,
    path: "/whitepaper",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/component/WhitePaper")),
  },
  {
    exact: true,
    path: "/privacypolicy",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Terms/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Terms/Faqs")),
  },
  // {
  //   exact: true,
  //   path: "/notification",
  //   layout: HomeLayoutAfterLogin,
  //   component: lazy(() => import("src/views/pages/Terms/Notifications")),
  // },
  {
    guard: true,
    exact: true,
    path: "/walletscreen",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Payment/WalletScreen")),
  },
  {
    guard: true,
    exact: true,
    path: "/transaction_History",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/TransactionHistory/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/transaction-details",
    layout: HomeLayoutAfterLogin,
    component: lazy(() =>
      import("src/views/pages/TransactionHistory/TransactionDetails")
    ),
  },
  {
    exact: true,
    path: "/product-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Product/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/cart",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Cart/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/wishlist",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wishlist/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/order-review",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/OrderReview/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/my-profile",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Profile/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/notification",
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import("src/views/pages/Notification")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
