import React, { useContext, useState } from "react";
import clsx from "clsx";

import { Link } from "react-router-dom";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import { AiOutlineUser } from "react-icons/ai";
import IconButton from "@material-ui/core/IconButton";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Divider,
  Box,
  Select,
  InputLabel,
  Toolbar,
  makeStyles,
  Input,
  Button,
  Hidden,
  TextField,
  Container,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { PieChart as PieChartIcon, Info as InfoIcon } from "react-feather";
import Logo from "src/component/Logo";
// import SearchBar from "@material-ui/core/SearchBar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import { Card } from "@material-ui/core";
import axios from "axios";
import ApiConfig from "src/apiconfig";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { isValidNumber, isValidEmail } from "src/utils";
import { useWeb3React } from "@web3-react/core";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { SUPPORTED_WALLETS } from "src/connectors";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";

const drawerWidth = 290;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdb52d",
  },
  noborderselect: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "5px 5px 0 0",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  drawerHeader: {
    padding: "5px 10px",
    backgroundColor: "#F2EDED",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h4": {
      fontWeight: 500,
      fontSize: "23px",
      lineHeight: "30px",
      color: "#403B58",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  noBorder: {
    border: "none",
  },
  toolbar: {
    margin: "0",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "300",
    fontFamily: "'Montserrat', sans-serif",
    color: "#102A49",
  },
  navButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  ButtonNav: {
    fontSize: 24,
    lineHeight: 9,
    fontWeight: 700,
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  connectWallet: {
    "& .MuiButton-root:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
    },
  },
  images: {
    padding: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f6f6f6",
    justifyContent: "space-evenly",
    "& img": {
      width: "220px",
    },
  },
  searchDiv: {
    textAlign: "right",
  },
  searchbox: {
    height: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    maxWidth: "500px",
    margin: "auto",
    "& h5": {
      color: "#234795",
      fontSize: "30px",
    },
    "& div": {
      width: "100%",
    },
  },
  searchboxInside: {
    background: "#ccc",
    padding: "15px",
    borderRadius: "11px",
  },
  searchbutton: {
    position: "absolute",
    right: 0,
    color: "#234795",
  },
}));

const sections = [
  {
    items: [
      {
        title: "Home",
        icon: PieChartIcon,
        href: "/",
      },
      {
        title: "Shop",
        icon: PieChartIcon,
        href: "/shop",
      },
      {
        title: "Category",
        icon: PieChartIcon,
        href: "/category",
      },
      {
        title: "Whitepaper",
        icon: PieChartIcon,
        href: "/whitepaper",
      },
      {
        title: "Contact",
        icon: PieChartIcon,
        href: "/contact-us",
      },
      {
        title: "Offer",
        icon: PieChartIcon,
        href: "/offers",
      },
    ],
  },
];

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar
      className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
      elevation={0}
      style={
        history.location.pathname !== "/"
          ? { backgroundColor: "#fff" }
          : { backgroundColor: "#fff" }
      }
      position="relative"
      elevation={0}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default TopBar;

export function TopBarData() {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [emailId, setForgetEmail] = useState("");
  const [newOtp, setNewOtp] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const CHARCTER_LIMIT = 1;
  const [newPassword, setNewPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");

  const [selectedWallet, setSelectedWallet] = useState("METAMASK");
  const { account, chainId } = useWeb3React();

  const connectWallet = async (connector) => {
    await auth.connectToWallet(connector);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
    setOpen2(false);
    setOpen3(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
    setOpen1(false);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
    setOpen1(false);
  };

  const [tabview, setTabView] = useState("login");

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen1(false);
    setTimeout(() => {
      setTabView("login");
    });
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const [search, setSearch] = React.useState(false);

  const handleClickSearch = () => {
    setSearch(true);
  };

  const handleSearch = () => {
    setSearch(false);
  };

  const loginHandler = async () => {
    setIsSubmit(true);
    if (mobileNumber !== "" && password != "") {
      try {
        setIsUpdate(true);
        const respose = await axios.post(ApiConfig.auth, {
          email: mobileNumber,
          password: password,
          ipAddress: "",
          userAgent: "",
          location: "",
          deviceToken: "",
        });
        console.log("respose", respose);
        if (respose.data.status === 200) {
          toast.success(respose.data.message);
          auth.userLogIn(true, respose.data.data.token);
          setOpen1(false);
        } else {
          toast.warn(respose.data.message);
        }
        setIsUpdate(false);
      } catch (error) {
        setIsUpdate(false);
        toast.error(error.message);
        console.log("ERROR", error);
      }
    }
  };

  const signUpHandler = async () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      phoneNo !== "" &&
      isValidNumber(phoneNo) &&
      email !== "" &&
      isValidEmail(email) &&
      userPassword !== "" &&
      userPassword.length > 6
    ) {
      setIsUpdate(true);
      try {
        const res = await axios.post(ApiConfig.signup, {
          firstName,
          lastName,
          phoneNo,
          email,
          password: userPassword,
        });
        console.log("res", res);
        setIsUpdate(false);
        // setOpen2(false);
        setTabView("regis_otp");
        toast.warn(res.data.message);
      } catch (error) {
        setIsUpdate(false);
        toast.error(error.message);

        console.log("error", error);
      }
    }
  };

  const sendForgetPassOtp = async () => {
    if (
      emailId !== ""
      // isValidNumber(emailId)
    ) {
      setIsUpdate(true);
      try {
        const res = await axios.get(ApiConfig.forgetPasswordOtp, {
          params: {
            email: emailId,
          },
        });
        console.log("res", res);
        setIsUpdate(false);
        setTabView("otp");
        // if(res.status===200 || res.status===201){
        // }
        toast.warn(res.data.message);
      } catch (error) {
        setTabView("otp");
        setIsUpdate(false);
        toast.error(error.message);
        console.log("error", error);
      }
    }
  };

  const verifyRegOtp = async () => {
    if (newOtp !== "") {
      setIsUpdate(true);
      try {
        const res = await axios.get(ApiConfig.verifyUser, {
          params: {
            token: newOtp,
          },
        });
        console.log("res", res);
        setIsUpdate(false);
        setOpen2(false);
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message);
        } else {
          toast.warn(res.data.message);
        }
      } catch (error) {
        setIsUpdate(false);
        toast.error(error.message);
        console.log("error", error);
      }
    }
  };
  const verifyOtp = async () => {
    if (newOtp !== "") {
      setIsUpdate(true);
      try {
        const res = await axios.post(ApiConfig.verifySmsCodeOtp, {
          code: newOtp,
          ipAddress: "",
          source: "",
        });
        console.log("res", res);
        setIsUpdate(false);
        setTabView("reset");
        toast.warn(res.data.message);
      } catch (error) {
        setIsUpdate(false);
        toast.error(error.message);
        console.log("error", error);
      }
    }
  };

  const resetPassword = async () => {
    if (newPassword !== "" && cnfPassword !== "") {
      setIsUpdate(true);
      try {
        const res = await axios.post(ApiConfig.resetPassword, {
          password: newPassword,
          email,
          token: "string",
        });
        console.log("res", res);
        setIsUpdate(false);
        toast.warn(res.data.message);
      } catch (error) {
        setIsUpdate(false);
        toast.error(error.message);

        console.log("error", error);
      }
    }
  };

  return (
    <>
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Grid className="dflex justifybetween">
          <Logo
            width="102"
            onClick={() => {
              history.push("/");
            }}
          />
          {/* <Box flexGrow={1} /> */}
          <Hidden smDown>
            <Box>
              {sections[0].items.map((item, index) => {
                return (
                  <Link className={classes.navButton} to={item.href}>
                    {" "}
                    <Button className={classes.toolbar} key={`nav${index}`}>
                      {item.title}
                    </Button>
                  </Link>
                );
              })}
            </Box>
            <Box>
              <Button className={classes.toolbar} onClick={handleClickOpen1}>
                Login / Register
              </Button>{" "}
              |
              {/* <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='end'
                component={Link}
                to='/wishlist'
              >
                <FavoriteBorderOutlinedIcon />
              </IconButton> */}
              {/* <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='end'
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <HiOutlineShoppingBag />
              </IconButton> */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleClickSearch}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <HiOutlineShoppingBag />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleClickOpen1}
                className={clsx(open && classes.hide)}
              >
                <AiOutlineUser />
              </IconButton>
              <SwipeableTemporaryDrawer sections={sections} />
            </Box>
          </Hidden>
        </Grid>
      </Container>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h4">Your Cart</Typography>
          <IconButton onClick={handleDrawerClose}>
            <GrClose />
          </IconButton>
        </div>
        <Box
          mb={2}
          p={2}
          className="cartImageBox"
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <figure>
            <img src="/images/product1.png" alt="" />
          </figure>
          <Box>
            <Typography variant="h5">EV Chargers</Typography>
            <Typography variant="h6">
              1 x <span>$299.00</span>
            </Typography>
          </Box>
          <IoMdClose style={{ fontSize: "14px", color: "#121313" }} />
        </Box>
        <Box className="subTotalBox" mt={2}>
          <Box p={2}>
            <Divider />
          </Box>

          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Subtotal</Typography>
            <Typography variant="body2">$1098.00</Typography>
          </Box>
          <Box p={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ marginBottom: "15px" }}
              component={Link}
              to="/cart"
            >
              View Cart
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              component={Link}
              to="/order-review"
            >
              Checkout
            </Button>
          </Box>
        </Box>
      </Drawer>

      {/* //LOGIN */}
      <Dialog
        open={open1}
        keepMounted
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          spacing={0}
          alignItems="stretch"
          style={{ background: "#F6F6F6" }}
        >
          <Hidden smDown>
            <Grid item xs={12} sm={6}>
              <img
                src="/images/login.png"
                alt=""
                style={{ display: "block", width: "100%" }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <Box style={{ background: "#F6F6F6", height: "100%" }}>
              {tabview === "login" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Box
                      className={classes.connectWallet}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="h4"
                      >
                        Login
                      </Typography>

                      {/* Don't have an account?{" "} */}
                      <Button
                        variant="text"
                        // color="primary"
                        size="large"
                        style={{ color: "#005DAC" }}
                        align="center"
                        onClick={handleClickOpen3}
                      >
                        <Typography variant="h4" className="dflex">
                          Connect Wallet
                        </Typography>
                      </Button>
                    </Box>
                    <TextField
                      label="Email"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter your email"
                      fullWidth
                      onChange={(e) => setMobileNumber(e.target.value)}
                      className="loginInput"
                      margin="normal"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      error={isSubmit && mobileNumber === ""}
                      helperText={
                        isSubmit &&
                        mobileNumber === "" &&
                        "Please enter password"
                      }
                    />
                    <TextField
                      label="Password"
                      variant="outlined"
                      type="password"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter your password"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      error={isSubmit && password === ""}
                      helperText={
                        isSubmit && password === "" && "Please enter password"
                      }
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                      onClick={() => loginHandler()}
                      disabled={isUpdate}
                    >
                      Login {isUpdate && <ButtonCircularProgress />}
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      size="large"
                      style={{
                        margin: "15px auto 0",
                        display: "block",
                        color: "#403B58",
                      }}
                      align="center"
                      onClick={() => setTabView("forget")}
                    >
                      Forget Password?
                    </Button>

                    <Typography variant="h6" className="dflex">
                      Don't have an account?{" "}
                      <Button
                        variant="text"
                        color="primary"
                        size="large"
                        style={{ color: "#005DAC" }}
                        align="center"
                        onClick={handleClickOpen2}
                      >
                        Register
                      </Button>
                    </Typography>

                    {/* <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={handleClickOpen3}
                      style={{ margin: "15px 0 0" }}
                    >
                      Connect Wallet
                    </Button> */}
                  </Box>
                </>
              ) : (
                ""
              )}

              {tabview === "forget" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">Forgot Password ?</Typography>
                    {/* <TextField
                      label="Phone Number"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "20px 0 10px" }}
                      placeholder="Enter your phone number"
                      fullWidth
                      className="loginInput"
                      margin="normal"
                      onChange={(e) => setForgetEmail(e.target.value)}
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    /> */}
                    <TextField
                      label="Email"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "20px 0 10px" }}
                      placeholder="Enter your Email"
                      fullWidth
                      className="loginInput"
                      margin="normal"
                      onChange={(e) => setForgetEmail(e.target.value)}
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                      onClick={sendForgetPassOtp}
                    >
                      Submit {isUpdate && <ButtonCircularProgress />}
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
              {tabview === "otp" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">OTP Verification</Typography>
                    <Typography variant="body2">
                      Please enter the 4-digit OTP you received on your
                      registered phone number.
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "20px 0 10px", width: "240px" }}
                        className="loginInput"
                        margin="normal"
                        onChange={(e) => setNewOtp(e.target.value)}
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                      onClick={() => verifyOtp()}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
              {tabview === "reset" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">Reset Password</Typography>
                    <TextField
                      label="New Password"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter New Password"
                      fullWidth
                      className="loginInput"
                      margin="normal"
                      onChange={(e) => setNewPassword(e.target.value)}
                      error={isSubmit && newPassword === "" ? true : false}
                      helperText={
                        isSubmit && newPassword === ""
                          ? "Please enter New password"
                          : ""
                      }
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                    <TextField
                      label="Confirm Password"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter Confirm Password"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      onChange={(e) => setCnfPassword(e.target.value)}
                      error={
                        isSubmit && newPassword === ""
                          ? true
                          : isSubmit && cnfPassword !== newPassword
                          ? true
                          : false
                      }
                      helperText={
                        isSubmit && cnfPassword === ""
                          ? "Please enter confirm password"
                          : isSubmit && cnfPassword !== newPassword
                          ? "Password mismatch"
                          : ""
                      }
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                      onClick={resetPassword}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      {/* Registeration field */}
      <Dialog
        open={open2}
        keepMounted
        maxWidth="md"
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ background: "#F6F6F6" }}
        disableBackdropClick={isUpdate}
        disableEscapeKeyDown={isUpdate}
      >
        <Box style={{ background: "#F6F6F6" }}>
          <Box pt={7} textAlign="center">
            <Logo />
          </Box>
        </Box>
        <Grid
          container
          spacing={0}
          alignItems="stretch"
          style={{ background: "#F6F6F6" }}
        >
          <Grid item xs={12} sm={12}>
            <Box style={{ background: "#F6F6F6", height: "100%" }}>
              {tabview === "regis_otp" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    {/* <Logo /> */}
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">OTP Verification</Typography>
                    <Typography variant="body2">
                      Please enter the 4-digit OTP you received on your
                      registered phone number.
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "20px 0 10px", width: "240px" }}
                        className="loginInput"
                        margin="normal"
                        onChange={(e) => setNewOtp(e.target.value)}
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                      onClick={() => verifyRegOtp()}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              ) : (
                <Box px={3} mt={5} pb={3}>
                  <Typography variant="h4">Personal Information</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "8px 0" }}
                        placeholder="First Name"
                        fullWidth
                        className="loginInput"
                        margin="normal"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        error={isSubmit && firstName === ""}
                        helperText={
                          isSubmit &&
                          firstName === "" &&
                          "Please enter first name"
                        }
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "8px 0" }}
                        placeholder="Last Name"
                        fullWidth
                        margin="normal"
                        className="loginInput"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        error={isSubmit && lastName === ""}
                        helperText={
                          isSubmit &&
                          lastName === "" &&
                          "Please enter last name"
                        }
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "8px 0" }}
                        placeholder="Phone Number"
                        fullWidth
                        margin="normal"
                        className="loginInput"
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        error={
                          isSubmit &&
                          (phoneNo === "" || !isValidNumber(phoneNo))
                        }
                        helperText={
                          isSubmit &&
                          (phoneNo === "" || !isValidNumber(phoneNo)) &&
                          "Please enter phone number"
                        }
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "8px 0" }}
                        placeholder="Email"
                        fullWidth
                        margin="normal"
                        className="loginInput"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={
                          isSubmit && (email === "" || !isValidEmail(email))
                        }
                        helperText={
                          isSubmit &&
                          (email === "" || !isValidEmail(email)) &&
                          "Please enter email"
                        }
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "8px 0" }}
                        placeholder="Password"
                        fullWidth
                        margin="normal"
                        className="loginInput"
                        value={userPassword}
                        type="password"
                        onChange={(e) => setUserPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </IconButton>
                          </InputAdornment>
                        }
                        error={
                          isSubmit &&
                          (userPassword === "" || userPassword.length < 6)
                        }
                        helperText={
                          isSubmit && userPassword === ""
                            ? "Please enter password"
                            : isSubmit &&
                              userPassword !== "" &&
                              userPassword.length < 6
                            ? "Password length must be grether then 6 latter"
                            : ""
                        }
                        // error={isSubmit && userPassword === ''}
                        // helperText={
                        //   isSubmit &&
                        //   userPassword === '' &&
                        //   'Please enter password'
                        // }
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        type="password"
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "8px 0" }}
                        placeholder="Confirm Password"
                        fullWidth
                        margin="normal"
                        className="loginInput"
                        value={userConfirmPassword}
                        onChange={(e) => setUserConfirmPassword(e.target.value)}
                        error={
                          isSubmit && userConfirmPassword === ""
                            ? true
                            : isSubmit && userConfirmPassword !== userPassword
                            ? true
                            : false
                        }
                        helperText={
                          isSubmit && userConfirmPassword === ""
                            ? "Please enter confirm password"
                            : isSubmit && userConfirmPassword !== userPassword
                            ? "Password mismatch"
                            : ""
                        }
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    style={{ margin: "15px 0 30px" }}
                    disabled={isUpdate}
                    onClick={signUpHandler}
                  >
                    Submit {isUpdate && <ButtonCircularProgress />}
                  </Button>

                  <Typography variant="h6" className="dflex">
                    Already have an account?{" "}
                    <Button
                      variant="text"
                      color="primary"
                      size="large"
                      style={{ color: "#005DAC" }}
                      align="center"
                      onClick={handleClickOpen1}
                    >
                      Login
                    </Button>
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={open3}
        keepMounted
        maxWidth="md"
        onClose={handleClose3}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box padding="50px">
          <Grid
            container
            spacing={3}
            alignItems="stretch"
            // style={{ background: "#F6F6F6" }}
          >
            <Grid item lg={12} md={12} sm={12}>
              {/* <img src="/images/metamask.svg" />
              <Button>Connect MetaMask</Button> */}
              <Box>
                <Box textAlign="center">
                  <Logo />
                </Box>
              </Box>
            </Grid>
            {SUPPORTED_WALLETS.map((data, i) => {
              return (
                <Grid item lg={6} md={6} sm={6}>
                  <Card className={classes.images}>
                    <img style={{ weight: "100px" }} src={data.data.iconName} />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      // style={{ margin: "15px 0 30px" }}
                      onClick={() => {
                        window.localStorage.removeItem("walletName");
                        window.localStorage.setItem("walletName", data.name);
                        // window.$('#selectChannel').modal('hide');
                        setSelectedWallet(data.data.name);
                        connectWallet(data.data);
                      }}
                    >
                      Connect MetaMask
                    </Button>
                  </Card>
                </Grid>
              );
            })}
            <Box textAlign="center">
              <Typography textAlign="center" style={{ color: "#ff7d68" }}>
                {auth.errorMsg}
              </Typography>
            </Box>
          </Grid>
          <Typography
            variant="h6"
            className="dflex"
            style={{ marginTop: "30px" }}
          >
            Already have an account?{" "}
            <Button
              variant="text"
              color="primary"
              size="large"
              style={{ color: "#005DAC" }}
              align="center"
              onClick={handleClickOpen1}
            >
              Login
            </Button>
          </Typography>
        </Box>
      </Dialog>

      {/* search modal */}
      <Dialog
        fullScreen
        className={classes.searchmodal}
        open={search}
        onClose={handleSearch}
        TransitionComponent={Transition}
      >
        <Box className={classes.searchDiv}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleSearch}
            aria-label="close"
            style={{ color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
          <Box className={classes.searchbox}>
            <Box className={classes.searchboxInside}>
              <Typography variant="h5" align="center" color="primary">
                Search
              </Typography>
              <Box style={{ position: "relative" }} mt={3}>
                <TextField label="Search Text" style={{ border: "#fff" }} />
                <IconButton
                  className={classes.searchbutton}
                  component={Link}
                  to="/search"
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {account && (
        <Dialog
          open={ACTIVE_NETWORK !== chainId}
          className="walletdailog"
          // onClose={handleClose}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="dailogTitle">
            {"Network Error"}
          </DialogTitle>
          <DialogContent className="loginModal">
            <DialogContentText id="alert-dialog-description">
              <Box className="walletlist">
                <Typography>Please swich to {NetworkContextName}</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
