import React, {useContext} from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import {} from "react-feather";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import Logo from "../../component/Logo";
import { ImPinterest2 } from "react-icons/im";
import { AuthContext } from "src/context/Auth";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#F2EDED",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& li": {
      color: "#494949",
      fontSize: "15px",
    },
    "& svg": {
      color: "#444444",
      fontSize: "20px",
      marginRight: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  // footerSection:{
  //   color: "#494949",
  // },
  lowerfootertext: {
    fontSize: " 15px",
    fontWeight: "normal",
    color: "#494949",
    fontFamily: "Roboto",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:768px)": {
      display: "block",
    },
  },
  imgboxx: {
    width: "40%",
    "@media(max-width:768px)": {
      width: "100%",
    },
  },
  circlebox: {
    backgroundColor: "red",
    borderRadius: "8px",
    width: "100px",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    backgroundColor: "#ece1e1",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  return (
    <>
      <Box className={classes.footerSection}>
        <Box style={{ margin: "20px 10px 0" }}>
          <Container maxWidth="lg">
            <Grid
              container
              style={{ color: "white" }}
              justify="space-around"
              spacing={1}
            >
              <Grid item xs={12} sm={3} md={3}>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  alignItems="flex-start"
                >
                  <Grid item>
                    {" "}
                    <Logo width="180" />{" "}
                  </Grid>
                  <Grid item>
                    {" "}
                    <Box mt={1} className="sociaLinks">
                      <List>
                        <ListItem>
                          <Link href="/">
                            <FaFacebookF onClick={()=> window.open((auth.staticData!=="" && auth.staticData.facebookUrl!==undefined) && auth.staticData.facebookUrl, "_blank")}/>
                          </Link>
                          <Link href="/">
                            <AiOutlineTwitter onClick={()=> window.open((auth.staticData!=="" && auth.staticData.twitterUrl!==undefined) && auth.staticData.twitterUrl, "_blank")}/>
                          </Link>
                          <Link href="/">
                            <TiSocialYoutubeCircular onClick={()=> window.open("https://www.youtube.com/", "_blank")}/>
                          </Link>
                          <Link href="/">
                            <ImPinterest2 onClick={()=> window.open("pinterest.com", "_blank")}/>
                          </Link>
                        </ListItem>
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      Customer Services
                    </Typography>

                    <List className="listingFooter">
                      <ListItem>
                        <Link to="/my-profile">My Account</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/faqs">FAQs</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/payment">Payment Methods</Link>
                      </ListItem>
                      {/* <ListItem>
                        <Link to="/account-review">Shipping Guide</Link>
                      </ListItem> */}
                      <ListItem>
                        <Link to="/wallet">Products Support</Link>
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      More From MNL
                    </Typography>
                    <List className="listingFooter">
                      <ListItem>
                        <Link to="/termsCondition">Terms & Conditions</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/privacypolicy">Privacy Policy</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/aboutus">About Us</Link>
                      </ListItem>
                    </List>{" "}
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      Let’s Talk
                    </Typography>
                    <List className="listingFooter">
                      <ListItem>
                      {(auth.staticData!=="" && auth.staticData.phone!==undefined) && auth.staticData.phone} <br />
                      {(auth.staticData!=="" && auth.staticData.emailAddress!==undefined) && auth.staticData.emailAddress}
                      {/* {(auth.staticData!=="" && auth.staticData.otherEmailAddress!==undefined) && auth.staticData.otherEmailAddress} */}
                      
                      </ListItem>
                      <ListItem>
                        <GrLocation />
                        {(auth.staticData!=="" && auth.staticData.emailAddress!==undefined) && auth.staticData.address} <br />
                        
                      </ListItem>
                    </List>{" "}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <hr />
                <Box
                  className={classes.lowerfootertext}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography className={classes.lowerfootertext1}>
                      Copyright © MNL all rights reserved. Powered by MNL Team
                    </Typography>
                  </Box>
                  <Box className={classes.imgboxx}>
                    <img
                      src="images/paymentpic.png"
                      style={{ width: "100%", height: "" }}
                    />
                  </Box>

                  {/* second method */}
                  {/* <Box className={classes.lowerfooter}>
                    <Box>
                      <Typography>
                        Copyright © MNL all rights reserved. Powered by MNL Team
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Box className={classes.boximg}>
                        <img
                          src="images/applelogo.png"
                          style={{
                            width: "70px",
                            height: "35px",
                            marginTop: "7px",
                          }}
                        />
                      </Box>
                      &nbsp; &nbsp;
                      <Box className={classes.boximg}>
                        <img
                          src="images/googlelogo.png"
                          style={{
                            width: "80px",
                            height: "35px",
                            marginTop: "7px",
                          }}
                        />
                      </Box>
                      &nbsp; &nbsp;
                      <Box className={classes.boximg}>
                        <img
                          src="images/sumsunglogo.png"
                          className={classes.boximg2}
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Box>
                      &nbsp; &nbsp;
                      <Box className={classes.boximg}>
                        <img
                          src="images/visalogo.png"
                          className={classes.boximg2}
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Box>
                      &nbsp; &nbsp;
                      <Box className={classes.boximg}>
                        <img
                          src="images/masterlogo.png"
                          className={classes.boximg2}
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Box>
                    </Box>
                  </Box> */}

                  {/* end  */}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
