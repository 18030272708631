import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  Container,
  Button,
  Hidden,
  MenuList,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { VscCallOutgoing, VscMail, VscChevronDown } from "react-icons/vsc";
import { FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";
import { ImPinterest2 } from "react-icons/im";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import {} from "react-feather";
import { toast } from "react-toastify";
import axios from "axios";
import ApiConfig from "src/apiconfig";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  headerSection: {
    background: "rgba(34,196,254)",
    backgroundColor: "#F6F6F6",
    top: "0px",
    height: "35px",
    position: "relative",
    width: "100%",
    left: "0",
    zIndex: "9",
    maxWidth: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
  },

  iconText: {
    width: 18,
    height: 18,
    marginRight: "8px",
    color: "#fff",
  },
  iconBox: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  iconSocial: {
    width: 15,
    height: 15,
    margin: "0 4px",
    color: "#444444",
  },
}));

export default function Header() {
  const classes = useStyles();
  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  // const [staticData, setStaticData] = useState("");
  const auth = useContext(AuthContext);

  

  // useEffect(() => {
  //       getStaticDataList();
  // }, []);

  // const getStaticDataList=async()=>{
    
  //     try {
  //          const respose = await axios.get(ApiConfig.getStaticDataListByIdAdmin, {
  //          params:{
  //           staticId:1
  //          },
            
  //         });
  //       console.log("respose", respose);
  //       if (respose.data.status === 200) {
  //         toast.success(respose.data.message);
  //         setStaticData(respose.data);
  //       } else {
  //         toast.warn(respose.data.message);
  //       }
  //     }
  //     catch (error) {
  //       // setIsUpdate(false);
  //       toast.error(error.message);
  //       console.log("ERROR", error);
  //     }
    
  // }

  const handleToggle = (name) => {
    
    setOpen1({ ...open1, [name]: !open1[name] });
  };

  return (
    <>
      <Hidden smDown>
        <Box className={classes.headerSection}>
          <Container maxWidth="lg">
            <Grid container>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<VscCallOutgoing />}
                  >
                    {(auth.staticData!=="" && auth.staticData.phone!==undefined) && auth.staticData.phone}
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    className={classes.button}
                    startIcon={<VscMail />}
                    style={{ marginLeft: "15px" }}
                  >
                    {(auth.staticData!=="" && auth.staticData.emailAddress!==undefined) && auth.staticData.emailAddress}
                    {/* info@mnltechnologies.com */}
                  </Button>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {/* <Button
                    variant="text"
                    size="small"
                    color="primary"
                    className={classes.button}
                    endIcon={<VscChevronDown />}
                    style={{ marginLeft: "15px" }}
                    ref={anchorRef.community}
                    aria-controls={
                      open1.community ? "menu-list-grow" : undefined
                    }
                    aria-haspopup="true"
                    onClick={() => handleToggle("community")}
                  >
                    Language
                  </Button> */}

                  <FaFacebookF className={classes.iconSocial} onClick={()=> window.open((auth.staticData!=="" && auth.staticData.facebookUrl!==undefined) && auth.staticData.facebookUrl, "_blank")}/>
                  <FaTwitter className={classes.iconSocial} onClick={()=> window.open((auth.staticData!=="" && auth.staticData.twitterUrl!==undefined) && auth.staticData.twitterUrl, "_blank")}/>
                  <FaYoutube className={classes.iconSocial} onClick={()=> window.open("https://www.youtube.com/", "_blank")}/>
                  <ImPinterest2 className={classes.iconSocial} onClick={()=> window.open("pinterest.com", "_blank")}/>

                  <Popper
                    open={open1.community}
                    anchorEl={anchorRef.community.current}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <div className={classes.paper}>
                          <MenuList>
                            <MenuItem>English</MenuItem>
                            <MenuItem>French</MenuItem>
                          </MenuList>
                        </div>
                      </Fade>
                    )}
                  </Popper>
                </Box>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Hidden>
    </>
  );
}
