import React from "react";
import { makeStyles } from "@material-ui/core";
import Footer from "./Footer";
import Header from "./Header";
import TopBar from "src/layouts/HomeLayout/TopBar";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#F6F6F6",
  },
  mainBox:{
    minHeight:"calc(100vh - 450px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <TopBar />
      <div className={classes.mainBox}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
