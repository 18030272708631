import React, { Suspense, Fragment, useContext } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { routes } from 'src/routes';
import { createBrowserHistory } from 'history';
import AuthContext from 'src/context/Auth';
import PageLoading from 'src/component/PageLoading';
import AuthGuard from 'src/component/AuthGuard';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from 'src/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext as UserAuthContext } from 'src/context/Auth';
import HomeLayout from 'src/layouts/HomeLayout';
import HomeLayoutAfterLogin from 'src/layouts/HomeLayoutAfterLogin';

const history = createBrowserHistory();

function App() {
  const theme = createTheme();
  console.log(theme);
  return (
    <div className='App'>
      <ToastContainer />

      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <Router history={history}>
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  const auth = useContext(UserAuthContext);
  return (
    <Suspense fallback={<PageLoading />}>
      {auth.isLoading ? (
        <PageLoading />
      ) : (
        <Switch>
          {props.data.map((route, i) => {
            const Component = route.component;
            const Guard = route.guard ? AuthGuard : Fragment;
            const layout = auth.isLogin ? HomeLayoutAfterLogin : HomeLayout;
            const Layout = layout || Fragment;
            return (
              <Route
                exact={route.exact}
                key={i}
                path={route.path}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        <RenderRoutes data={route.routes} />
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      )}
    </Suspense>
  );
}
