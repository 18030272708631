import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Avatar,
  makeStyles,
  Button,
  Hidden,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { PieChart as PieChartIcon, Info as InfoIcon } from "react-feather";
import Logo from "src/component/Logo";
import { SUPPORTED_WALLETS } from "src/connectors";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import { useWeb3React } from "@web3-react/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdb52d",
    borderBottom: "2px solid #D1D0D0",
  },
  toolbar: {
    margin: "10px 0 0",
    paddingBottom: "10px",

    borderBottom: "2px solid #D1D0D0",
    "& a": {
      "& button": {
        borderBottom: "0",
        fontSize: "18px",
        lineHeight: "18px",
        fontWeight: "600",
        fontFamily: "'Montserrat', sans-serif",
        color: "#102A49",
      },
    },
  },
  buttonImg: {
    width: 40,
    height: 40,
    backgroundColor: "#0099FF",
    "& img": {
      width: "30px",
      height: "31px",
    },
  },
  buySwap: {
    borderRadius: "50px",
    backgroundColor: "#fff",
    padding: "4px 15px 4px 14px ",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.05)",
    height: "50px",
    fontSize: "17px",
    lineHeight: "27px",
    color: "#0099FF",
    fontWeight: "600",
    "@media (max-width: 767px)": {
      fontSize: "15px",
      lineHeight: "25px",
    },
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  login: {
    border: "4px solid #FABC12",
  },
  connectWallet: {
    borderRadius: "50px",
    border: "1px solid #EBEBEB",
    backgroundColor: "#fff",
    padding: "4px 15px 4px 14px ",
    height: "50px",
    fontSize: "14px",
    lineHeight: "19px",
    color: "##464255",
    fontWeight: "600",
    margin: "0 15px",
    fontFamily: "'Montserrat', sans-serif",
    "@media (max-width: 767px)": {
      fontSize: "14px",
      lineHeight: "19px",
    },
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  navButton: {
    marginLeft: theme.spacing(8),
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  ButtonNav: {
    fontSize: 24,
    lineHeight: 9,
    fontWeight: 700,
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/dashboard",
      },
      {
        title: "Stake & Earn",
        icon: PieChartIcon,
        href: "/stake",
      },
    ],
  },
];

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar
      className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
      elevation={0}
      style={
        history.location.pathname !== "/"
          ? { backgroundColor: "#fff" }
          : { backgroundColor: "#fdb52d" }
      }
      position='relative'
      elevation={0}
      color='default'
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const { account, chainId } = useWeb3React();

  return (
    <>
      <Container maxWidth='lg'>
        <Grid className='flexmenu'>
          <Logo
            width='102'
            onClick={() => {
              history.push("/");
            }}
          />
          <Box className='DASHBOARDMENU'>
            <Box>
              <Hidden smDown>
                {sections[0].items.map((item, index) => {
                  return (
                    <Link className={classes.navButton} to={item.href}>
                      {" "}
                      <Button className={classes.toolbar} key={`nav${index}`}>
                        {item.title}
                      </Button>
                    </Link>
                  );
                })}
              </Hidden>
            </Box>
            <Box>
              <Hidden smDown>
                <Button
                  className={classes.buySwap}
                  startIcon={
                    <Avatar
                      className={classes.buttonImg}
                      src={"images/pancake.svg"}
                    />
                  }
                >
                  Buy on PancakeSwap
                </Button>
                <Button className={classes.connectWallet}>
                  Connect to Wallet
                </Button>
                <IconButton
                  aria-label='delete'
                  size='small'
                  className={classes.login}
                >
                  {" "}
                  <Avatar
                    className={classes.loginButton}
                    src={"images/user1.png"}
                  />
                </IconButton>
              </Hidden>
            </Box>
          </Box>
          <Hidden mdUp>
            <SwipeableTemporaryDrawer sections={sections} />
          </Hidden>
        </Grid>
      </Container>
      {account && (
        <Dialog
          open={ACTIVE_NETWORK !== chainId}
          className='walletdailog'
          // onClose={handleClose}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
        >
          <DialogTitle id='alert-dialog-title' className='dailogTitle'>
            {"Network Error"}
          </DialogTitle>
          <DialogContent className='loginModal'>
            <DialogContentText id='alert-dialog-description'>
              <Box className='walletlist'>
                <Typography>Please swich to {NetworkContextName}</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
