const url = "https://java-whitepaper.mobiloitte.org";

const account = `${url}/account`;
const product = `${url}/product`;

const auth = `${url}/auth`;
const staticData = `${url}/static`;
const stripeService = `${url}/stripe-service`;
const stripe = `${url}/stripe`;

const notification = `http://182.72.203.247:4090`;
const urlNotification = `${url}/notification`;
const ApiConfig = {
  auth: auth,

  //ACCOUNT
  myAccount: `${account}/my-account`,
  profileUpdate: `${account}/profile-update`,
  signup: `${account}/signup`,
  getStateCountryWise: `${account}/get-state-country-wise`,
  forgetPasswordOtp: `${account}/forget-password/otp`,
  verifySmsCodeOtp: `${account}/verify-sms-code-otp`,
  resetPassword: `${account}/reset-password`,
  verifyUser: `${account}/verify-user`,
  changePassword: `${account}/change-password`,
  getCountryList: `${account}/get-country-list`,

  //category

  getAllcategoryList: `${product}/get-all-categoryList`,
  getAllfiltercategoryList: `${product}/getAllCategoryList`,
  getcategoryFilterList: `${notification}/product/get-search-filter`,
  gethomeAPIfilter: `${notification}/product/homepage-api`,

  //brand
  // getAllBrandList: `${product}/get-Brand-List`,
  getAllBrandList: `${product}/getAllBrandList`,

  //product
  getAllProductList: `${product}/getAllProductList`,
  getSearchFilter: `${product}/get-search-filter`,
  viewProductbyId: `${product}/view-Product-by-Id`,
  addProductLike: `${product}/add-ProductLike`,
  dislikeProduct: `${product}/Dislike-product`,
  getOrderDetails: `${product}/get-Order-Details`,
  addCart: `${product}/add-cart`,
  getAddCartByuserId: `${product}/get-AddCart-By-userId`,
  deleteAddCart: `${product}/delete-AddCart`,
  getAddCartByProductId: `${product}/get-AddCart-By-productId`,
  getAllDiscountList: `${product}/get-All-DiscountList`,
  getDiscountByUserId: `${product}/get-Discount-By-userId`,
  getShippingAddressByUserId: `${product}/get-ShippingAddress-By-userId`,
  addShippingAddress: `${product}/add-shipping-address`,
  editShippingAddress: `${product}/edit-shipping-address`,
  deleteShippingAddress: `${product}/delete-ShippingAddress`,
  getLikeListByUserid: `${product}/get-like-list-by-userid`,
  getAllOrderDetails: `${product}/get-orderHistory-By-userId`,
  getProductDetails: `${product}/get-Product-Details`,
  addRating: `${product}/add-Rating`,
  findPromoCode: `${product}/find-coupan-name-code`,
  getRatingByProductId: `${product}/get-Rating-By-productId`,
  reOrderProduct: `${product}/reorder-of-Product`,
  getLikedProduct: `${product}/get-like-By-productId`,
  getLikedListByUserId: `${product}/get-like-list-by-userid`,
  getProductByCategoryId: `${product}/get-Product-by-CategoryId`,

  getDiscountList: `${product}/get-Discount-Details`,
  getBestSellerProductList: `${product}/best-seller-product-list-in-a-week-by-Product`,

  getReview: `${product}/get-Total-Rating-By-productId?productId=`,
  getCustomerReview: `${product}/get-Rating-By-productId?productId=`,
  buyOrder: `${product}/add-buy-multiple-order`,
  getOfferProductId: `${product}/get-Offer-By-productId?productId=`,
  getOrderHistory: `${product}/get-orderHistory-By-userId`,

  getOrderByRondomId: `${product}/get-product-by-orderId?randomOrderId=`,

  //static
  getStaticContent: `${staticData}/get-static-content`,
  addProductEnquiry: `${staticData}/add-ProductEnquiry`,
  getbannerlist: `${staticData}/get-banner-list`,
  getbannerlist: `${staticData}/get-banner-list`,
  saveStaticData: `${staticData}/save-static-data`,
  getStaticDataListByIdAdmin: `${staticData}/get-static-data-list-by-id-admin`,
  getTestimonials: `${staticData}/get-testimonials-data-list`,
  getFaqlist: `${staticData}/get-faq-detail-list`,
  getNotification: `${urlNotification}/get-notification-data`,

  //stripeService
  paymentAddCard: `${stripe}/payment/addCard?email=`,
  paymentRetriveCard: `${stripe}/payment/retrieveCustomers`,

  addCard: `${stripeService}/add-card`,
  updateCard: `${stripeService}/update-card`,
  deleteCardByCardDetailsId: `${stripeService}/payment/delete-card?stripeCardId=`,
  getCardListByuserId: `${stripeService}/get-Card-By-userId`,
  // getCardListByuserId: `${stripeService}/get-Card-By-userId`,
  charge: `${stripe}/payment/charge`,
  // charge: `${stripeService}/payment/charge`,
  getPaymentHistory: `${stripeService}/payment/get-payment-History`,
  getAllCard: `${stripeService}/payment/get-Card-By-userId`,

  

  //notification
  getNotificationData: `${notification}/notification/get-notification-data`,
  getOfferData: `${notification}/product/get-All-OfferList`,
  getTransactionDetails: `${notification}/stripe/payment/get-payment-History`,
};

export default ApiConfig;
