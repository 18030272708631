import React from 'react';
import {
  makeStyles,
  Box,
  Container,
  LinearProgress,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export function DataLoading() {
  return (
    <Container>
      <Box
        display='flex'
        height={500}
        alignItems='center'
        justifyContent='center'
      >
        <Box width={400}>
          <LinearProgress />
        </Box>
      </Box>
    </Container>
  );
}

export function DataNotFound() {
  return (
    <Container>
      <Box display='flex' height={300} justifyContent='center'>
        <Box mt={2}>
          <Typography>Data Not Found</Typography>
        </Box>
      </Box>
    </Container>
  );
}
