import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/apiconfig";
import { SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", `Bearer ${accessToken}`);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    // delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setuserData] = useState();
  const urll = new URL(window.location.href);
  const productId = urll.searchParams.get("id");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [cartList, setCartList] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [totalShippingCharge, setShippingChargeTotal] = useState(0);
  const [totalAmount, setTotal] = useState(0);
  const [shippingAddressList, setShippingAddressList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [cardList, setCardList] = useState([]);
  const [category, setcategory] = useState([]);
  const [brands, setBrands] = useState([]);
  const [allOrderList, setAllOrderList] = useState([]);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [allProducts, setproduct] = useState([]);
  const [tokenPrice, setTokenPrice] = useState();
  const { activate, account, deactivate } = useWeb3React();
  const [staticData, setStaticData] = useState("");
  const [userproductLikes, setUserproductLikes] = useState([]);
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [selectedPromoCode, setSelectedPromoCode] = useState();
  const [discountAmount,setDiscountAmount]=useState("");
  const [allCardList, setAllCardList] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const getProfile = async (cancelTokenSource) => {
    try {
      const authorization = localStorage.getItem("creatturAccessToken");
      const res = await axios.get(ApiConfig.myAccount, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,

        headers: {
          authorization,
        },
      });
      if (res.data.status === 200) {
        console.log("dataaa+++++++>", res.data.data);
        getCardList()
        localStorage.setItem("userId", res.data.data.userId);
        setIsLogin(true);
        setuserData(res.data.data);
      } else {
        setIsLogin(false);
        localStorage.clear()
        localStorage.removeItem("creatturAccessToken");
        setuserData();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLogin(false);
      localStorage.clear()
      localStorage.removeItem("creatturAccessToken");
      console.log("error", error);
      setuserData();
      setIsLoading(false);
    }
  };

  const connectToWallet = (data) => {
    const connector = data.connector;
    setErrorMsg("");
    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log("error", error.message);
        setErrorMsg(error.message + " Please install " + data.name);
        activate(connector);
      }
    });
  };

  useEffect(() => {

    if (window.localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.localStorage.getItem("walletName")
      );
      // connectToWallet(selectectWalletDetails[0].data);
    }
  }, []);


  const promoCodeListHandler = async (cartListdata) => {
    const authorization = localStorage.getItem("creatturAccessToken");
    // getDiscountList
    try {
      const res = await axios.get(ApiConfig.getDiscountList, {
        headers: {
          authorization,
        },
      });

      if (res.data.status === 200) {
        const list = [];
       let listProductWisePromo=[]
        res.data.data.forEach(last=>{
          if(last.discountStatus == "ACTIVE" && last.discountType == "ASSIGNED_TO_PRODUCT"){
            listProductWisePromo.push(last)
          }
        })
        const orderBylist = res.data.data.filter(
          (item) =>
            item.discountType == "ASSIGNED_TO_TOTAL_ORDER" &&
            item.discountStatus === "ACTIVE"
        );
        if (orderBylist.length > 0) {
          orderBylist.forEach((it) => {
            listProductWisePromo.push(it);
          });
        }

        setPromoCodeList(listProductWisePromo);
        setSelectedPromoCode(listProductWisePromo[0].coupanCode);
      }
    } catch (error) {}
  };
  const getOffer = async () => {
    try {
      const authorization = localStorage.getItem("creatturAccessToken");
      const res = await axios.get(`${ApiConfig.getOfferProductId}${productId}`, {
        headers: {
          authorization,
        },
      });
      if (res.data.status === 200 && res.data.data.length > 0) {
        if (res.data.data[0].offerStatus == "ACTIVE") {
          setOfferData(res.data.data);
        }
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
    }
  };
  const applyPromoCode = (selectedPromoCode) => {
    const applicablePromo = promoCodeList.filter(function(item) {
      return item.coupanCode === selectedPromoCode
    })
  
    if(applicablePromo.length>0){
      if (applicablePromo[0].discountType == "ASSIGNED_TO_PRODUCT") {
        if(cartList){
          const productPrice = cartList.filter(function(item) {
            return  parseInt(item.productId) === parseInt(applicablePromo[0].productName)
          })
         const ProdDiscount = (applicablePromo[0].discountPercentage / 100) * productPrice[0].price;
          if (parseInt(applicablePromo[0].maxDiscountAmount) >= parseInt(ProdDiscount)) {
            setDiscountAmount(ProdDiscount);
          } else {
            toast.error("not applicable !");
            setDiscountAmount("");
          }
        }
      }
    }
 

     if(applicablePromo.length>0){
      if (applicablePromo[0].discountType == "ASSIGNED_TO_TOTAL_ORDER") {
        const onOrderDiscount =
          (applicablePromo[0].discountPercentage / 100) * orderTotal;
        if (parseInt(applicablePromo[0].maxDiscountAmount) >= parseInt(onOrderDiscount)) {
          setDiscountAmount(onOrderDiscount);
        } else {
          toast.error("not applicable !");
          setDiscountAmount("");
        }
      }
     }
       

    // productPrice
    // promoCodeList.map((data, i) => {
    //   if (data.coupanCode == selectedPromoCode) {
        if (data.discountType == "ASSIGNED_TO_PRODUCT") {
          const ProdDiscount = (data.discountPercentage / 100) * data.price;
          if (parseInt(data.maxDiscountAmount) >= parseInt(ProdDiscount)) {
            setDiscountAmount(ProdDiscount);
          } else {
            toast.error("not applicable !");
            setDiscountAmount("");
          }

        }
    //     if (data.discountType == "ASSIGNED_TO_TOTAL_ORDER") {
    //       const onOrderDiscount =
    //         (data.discountPercentage / 100) * orderTotal;
    //       if (parseInt(data.maxDiscountAmount) >= parseInt(onOrderDiscount)) {
    //         setDiscountAmount(onOrderDiscount);
    //       } else {
    //         toast.error("not applicable !");
    //         setDiscountAmount("");
    //       }
    //     }
    //   }
    // });
  };
 
  const buyerOrderPost = async (selectedAddress,paymentType,discountAmount,discountPercentage,offerAmount,offerPercentage) => {
    const requestpayLoad = []
    try{
      const authorization = localStorage.getItem("creatturAccessToken");
      if(cartList.length>0){
      let totalAmount=0
      let totalShipping=0

      cartList.forEach(item=>{
        const today = new Date()
        totalAmount += parseInt(item.price);
        totalShipping += parseInt(item.additionalCharge);
       const list= {
          "amount": parseInt(item.price),
          "grandtotal":totalAmount,
          "shippingAmount" :totalShipping,
          "discountAmount" :discountAmount,
          "discountPercentage" :discountPercentage,
          "offerAmount" :offerAmount,
          "offerPercentage" :offerPercentage,
          "categoryId": item.category.categoryId,
          "createTime": today.toISOString().split('T')[0],
          "fromAddress":staticData.address,
          "orderId": 0,
          "orderStatus": "SHIPPED",
          "paymentMethod":paymentType,
          "paymentStatus": "DONE",
          "productId": item.productId,
          "productName": item.productName,
          "ratingId": "",
          "shippingAddressId": selectedAddress.shippingAddressId,
          "toAddress": `${selectedAddress.address}, ${selectedAddress.area},${selectedAddress.landmark},${selectedAddress.city},${selectedAddress.country},${selectedAddress.state}`,
          "totalquantity": item.quantity,
          "updateTime":today.toISOString().split('T')[0],
          "userId": item.userId,
          "userName": selectedAddress.name
        }
        requestpayLoad.push(list)
      })
    }
      const res =  await axios.post(
        ApiConfig.buyOrder,requestpayLoad,
        {
          headers: {
            authorization,
          },
        }
      );
      console.log("res", res);
      if (res.data.status === 200) {
        toast.success("your order placed");
        setCartList([])
        // setOrderTotal(parseFloat(0).toFixed(2));
        // setTotal(parseFloat(0).toFixed(2));
        // setShippingChargeTotal(parseFloat(0).toFixed(2));
       
      }

    } catch (error) {
      console.log("ERROR", error);
    }
  }




  const getCartDetails = async () => {
    try {
      const authorization = localStorage.getItem("creatturAccessToken");
      const res = await axios.get(ApiConfig.getAddCartByuserId, {
        headers: {
          authorization,
        },
      });
      if (res.data.status === 200) {
        
        const result = res.data.data.filter(
          (data) =>
            data.productId
        );
        let orderPrice = 0;
        let shippingCharge = 0;
        for (let i = 0; i < result.length; i++) {
          let ml =parseFloat(result[i].variantPrice)*parseFloat(result[i].quantity)
          orderPrice += parseFloat(ml);
        }
        for (let i = 0; i < result.length; i++) {
          shippingCharge += parseFloat(result[i].additionalCharge);
        }
        let  totalAmount = parseFloat(orderPrice) + parseFloat(shippingCharge)
        setOrderTotal(parseFloat(orderPrice).toFixed(2));
        setShippingChargeTotal(parseFloat(shippingCharge).toFixed(2));
        setTotal(parseFloat(totalAmount).toFixed(2));


        setCartList(result);
        promoCodeListHandler(result);
      } else if(res.data.status === 205){
        setOrderTotal(parseFloat(0).toFixed(2));
        setTotal(parseFloat(0).toFixed(2));
        setShippingChargeTotal(parseFloat(0).toFixed(2));
        setCartList([])

      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  // const getCarDetails = async () => {
  //   try {
  //     const authorization = localStorage.getItem("creatturAccessToken");

  //     const res = await axios.get(ApiConfig.getCardListByuserId, {
  //       headers: {
  //         authorization,
  //       },
  //     });
  //     console.log("res", res);

  //     if (res.data.status === 200) {
  //       setCardList(res.data.data);
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // };

  const getOrderList = async () => {
    try {
      const authorization = localStorage.getItem("creatturAccessToken");

      const res = await axios.get(ApiConfig.getAllOrderDetails, {
        headers: {
          authorization,
        },
      });
      console.log("res", res);

      if (res.data.status === 200) {
        setAllOrderList(res.data);
      } else {
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  

  const shippingAddressListHandler = async () => {
    const authorization = localStorage.getItem("creatturAccessToken");

    try {
      const res = await axios.get(ApiConfig.getShippingAddressByUserId, {
        headers: {
          authorization,
        },
      });
      console.log("res", res);
      if (res.data.status === 200) {
        setShippingAddressList(res.data.data.reverse());
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getStateList = async (countryCode) => {
    try {
      const res = await axios.get(ApiConfig.getStateCountryWise, {
        params: {
          countryCode: countryCode,
        },
      });
      if (res.data.status === 200) {
        setStateList(res.data.data);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getStaticDataList = async () => {
    try {
      const respose = await axios.get(ApiConfig.getStaticDataListByIdAdmin, {
        params: {
          staticId: 1,
        },
      });
      console.log("respose", respose);
      if (respose.data.status === 200) {
        setStaticData(respose.data.data);
      }
    } catch (error) {
      // setIsUpdate(false);
      console.log("ERROR", error);
    }
  };

  const getAllCategoryListHanlder = async () => {
    try {
      const res = await axios.get(ApiConfig.getAllfiltercategoryList);
      console.log("res category", res);
      if (res.data.status == 200) {
        setcategory(res.data.data[0].categoryDetails);
      }
      //   setIsLoading(false);
    } catch (error) {
      //   setIsLoading(false);
      console.log("error", error);
    }
  };
  const getCountryList = async () => {
    try {
      const res = await axios.get(ApiConfig.getCountryList);
      if (res.data.status === 200) {
        setCountryList(res.data.data);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const getAllBrandsListHanlder = async () => {
    try {
      const res = await axios.get(ApiConfig.getAllBrandList);
      console.log("res Brands", res);
      if (res.data.status == 200) {
        setBrands(res.data.data);
      }
      //   setIsLoading(false);
    } catch (error) {
      //   setIsLoading(false);
      console.log("error", error);
    }
  };
  const getLikedListByUserId = async () => {
    try {
      const authorization = localStorage.getItem("creatturAccessToken");

      const res = await axios.get(ApiConfig.getLikedListByUserId, {
        headers: {
          authorization,
        },
      });
      console.log("res of Like Auth", res);
      if (res.data.status === 200) {
        setUserproductLikes(res.data.data);
        // toast.success(res.data.message);
        // setLike(!like);
        // getLikeProduct();
        // getAllProductListHanlder();
      } else {
        // toast.warn(res.data.message);
      }
    } catch (error) {
      // toast.error(error.message);

      console.log("ERROR", error);
    }
  };

  const getAllProductListHanlder = async () => {
    const authorization = localStorage.getItem("creatturAccessToken");

    try {
      const res = await axios.get(
        ApiConfig.getAllProductList,
        {},
        {
          headers: {
            authorization,
          },
        }
      );
      console.log("res", res);
      if (res.data.status == 200) {
        setproduct(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const getAllCategoryList = async () => {
    try {
      const authorization = localStorage.getItem("creatturAccessToken");

      const res = await axios.get(ApiConfig.getAllfiltercategoryList);
      console.log("res category", res);
      if (res.data.status === 200) {
        setAllCategoryList(res.data.data[0].categoryDetails);
        // toast.success(res.data.message);
        // setLike(!like);
        // getLikeProduct();
        // getAllProductListHanlder();
      } else {
        // toast.warn(res.data.message);
      }
    } catch (error) {
      // toast.error(error.message);

      console.log("ERROR", error);
    }
  };
  
  const getLiveEthPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=busd&vs_currencies=USD"
      );
      console.log("getLiveEthPrice", res);
      if (res.status === 200) {
        setTokenPrice(res.data.busd.usd);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const getCardList = async () => {
      const authorization = localStorage.getItem("creatturAccessToken");
      try {
        const res = await axios.get(`${ApiConfig.getAllCard}`, {
          headers: {
            authorization,
          },
        });
        if (res.data.status === 200) {
          // alert(res.data.data)
          const key = "fingerprint";
          const uniqueCard = [
            ...new Map(
              res.data.data.map((item) => [
                item[key],
                item,
              ])
            ).values(),
          ];
         
       setAllCardList(uniqueCard)
        } else {

        }
      } catch (error) {
      }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (isLogin) {
     
      getProfile(cancelTokenSource);
      getCartDetails();
      shippingAddressListHandler();
     
      // getCarDetails();
      getOrderList();
      getStaticDataList();
      getLikedListByUserId();

    
    } else {
      setIsLoading(false);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [isLogin]);

  useEffect(() => {
    getCountryList();
    getOffer();
    getAllCategoryListHanlder();
    getAllBrandsListHanlder();
    getStaticDataList();
    getAllCategoryList();
    getAllProductListHanlder();
    getLiveEthPrice();
  }, []);

  let data = {
    isLogin: isLogin,
    userData,
    isLoading,
    errorMsg,
    cartList,
    orderTotal,
    totalShippingCharge,
    shippingAddressList,
    stateList,
    cardList,
    countryList,
    staticData: staticData,
    category: category,
    brands: brands,
    offerData:offerData,
    allOrderList,
    userproductLikes,
    allCategoryList,
    allProducts,
    tokenPrice,
    discountAmount,
    promoCodeList,
    selectedPromoCode,
    allCardList,

    shippingAddressListHandler: () => shippingAddressListHandler(),
    getStateByCountryHandler: (countryCode) => getStateList(countryCode),
    applyPromoCode: (promocode) => applyPromoCode(promocode),
    // getCarDetails: () => getCarDetails(),
    connectToWallet: (connector) => connectToWallet(connector),
    getProfile: () => getProfile(),
    getCartDetails: () => getCartDetails(),
    buyOrder: (address,paymentMethod,discountAmount,discountPercentage,offerAmount,offerPercentage) => buyerOrderPost(address,paymentMethod,discountAmount,discountPercentage,offerAmount,offerPercentage),

    userLogIn: (loginStatus, token) => {
      setSession(token);
      setIsLogin(loginStatus);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
