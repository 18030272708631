import React, { useState, useContext } from "react";
import clsx from "clsx";

import { Link } from "react-router-dom";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import PropTypes from "prop-types";
import { BiWallet } from "react-icons/bi";
import { MdNotificationsNone } from "react-icons/md";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import { AiOutlineUser } from "react-icons/ai";
import IconButton from "@material-ui/core/IconButton";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { HiInformationCircle } from "react-icons/hi";
import { HiCurrencyDollar } from "react-icons/hi";
import Radio from "@material-ui/core/Radio";
import { BiReceipt } from "react-icons/bi";
import CallIcon from "@material-ui/icons/Call";
import {
  AppBar,
  Divider,
  Box,
  Select,
  InputLabel,
  Toolbar,
  makeStyles,
  Input,
  Button,
  Hidden,
  TextField,
  Container,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { PieChart as PieChartIcon, Info as InfoIcon } from "react-feather";
import Logo from "src/component/Logo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { RiFeedbackFill } from "react-icons/ri";
import { RiLockPasswordLine } from "react-icons/ri";
import { green } from "@material-ui/core/colors";
import MailIcon from "@material-ui/icons/Mail";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import ApiConfig from "src/apiconfig";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import { useWeb3React } from "@web3-react/core";

const drawerWidth = 290;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdb52d",
  },
  noborderselect: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "5px 5px 0 0",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  drawerHeader: {
    padding: "5px 10px",
    backgroundColor: "#F2EDED",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h4": {
      fontWeight: 500,
      fontSize: "23px",
      lineHeight: "30px",
      color: "#403B58",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  noBorder: {
    border: "none",
  },
  toolbar: {
    margin: "0",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "300",
    fontFamily: "'Montserrat', sans-serif",
    color: "#102A49",
  },
  navButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  ButtonNav: {
    fontSize: 24,
    lineHeight: 9,
    fontWeight: 700,
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
  root1: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const sections = [
  {
    items: [
      {
        title: "Home",
        icon: PieChartIcon,
        href: "/",
      },
      {
        title: "Shop",
        icon: PieChartIcon,
        href: "/shop",
      },
      {
        title: "Category",
        icon: PieChartIcon,
        href: "/category",
      },
      {
        title: "Whitepaper",
        icon: PieChartIcon,
        href: "/whitepaper",
      },
      // {
      //   title: "Download App",
      //   icon: PieChartIcon,
      //   href: "/",
      // },
      {
        title: "Contact",
        icon: PieChartIcon,
        href: "/contact-us",
      },
      {
        title: "Offer",
        icon: PieChartIcon,
        href: "/offers",
      },
    ],
  },
];

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const { account, chainId } = useWeb3React();

  return (
    <AppBar
      className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
      elevation={0}
      style={
        history.location.pathname !== "/"
          ? { backgroundColor: "#fff" }
          : { backgroundColor: "#fff" }
      }
      position="relative"
      elevation={0}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const [selectedValue, setSelectedValue] = React.useState("a");
  const auth = useContext(AuthContext);
  const { account, chainId } = useWeb3React();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const classes = useStyles();
  const history = useHistory();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
    setOpen2(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
    setOpen1(false);
  };

  const [tabview, setTabView] = useState("login");

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen1(false);
    setTimeout(() => {
      setTabView("login");
    });
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const logoutHandler =()=>{
    handleCloseMenu();
    history.push('/');
    auth.userLogIn(false, false);
    
  }
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose1 = () => {
  //   setOpen(false);
  // };

  const [open10, setOpen10] = React.useState(false);

  const handleClickOpen10 = () => {
    setOpen10(true);
  };

  const handleClose10 = () => {
    setOpen10(false);
  };

  const [open11, setOpen11] = React.useState(false);

  const handleClickOpen11 = () => {
    setOpen11(true);
  };

  const handleClose11 = () => {
    setOpen11(false);
  };

  const [open14, setOpen14] = React.useState(false);

  const handleClickOpen14 = () => {
    setOpen14(true);
  };

  const handleClose14 = () => {
    setOpen14(false);
  };

  const submitInquiryHandler = async () => {
    setIsSubmit(true);
    console.log("-----");
    if (subject !== "" && description !== "") {
      const authorization = localStorage.getItem("creatturAccessToken");

      try {
        setIsUpdate(true);
        const res = await axios.post(
          ApiConfig.addProductEnquiry,
          {
            description,
            subject,
          },
          {
            headers: {
              authorization,
            },
          }
        );
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setIsSubmit(false);
          setSubject("");
          setDescription("");
          handleClose11();
        } else {
          toast.warn(res.data.message);
        }
        setIsUpdate(false);
      } catch (error) {
        toast.error(error.message);
        console.log("ERROR", error);
      }
    }
  };

  const removeFromCartHandler = async (selectedItem) => {
    const authorization = localStorage.getItem("creatturAccessToken");
    if (selectedItem) {
      try {
        const res = await axios({
          method: "DELETE",
          url: ApiConfig.deleteAddCart,
          headers: {
            authorization,
          },
          params: {
            cartId: selectedItem.cartId,
          },
        });
        if (res.data.status === 200) {
          toast.success(res.data.message);
          auth.getCartDetails();
          handleClose();
        } else {
          toast.warn(res.data.message);
        }
      } catch (error) {
        toast.error(error.message);
        console.log("ERROR", error);
      }
    } else {
      toast.error("Please select Item");
    }
  };

  return (
    <>
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Grid className="dflex justifybetween">
          <Logo
            width="102"
            onClick={() => {
              history.push("/");
            }}
          />
          {/* <Box flexGrow={1} /> */}
          <Hidden smDown>
            <Box>
              {sections[0].items.map((item, index) => {
                return (
                  <Link className={classes.navButton} to={item.href}>
                    {" "}
                    <Button className={classes.toolbar} key={`nav${index}`}>
                      {item.title}
                    </Button>
                  </Link>
                );
              })}
            </Box>
            <Box>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.toolbar}
              >
                My Account <RiArrowDropDownLine />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                className="menudropdown"
              >
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/my-profile"
                >
                  {" "}
                  <FaUser /> My Profile
                </MenuItem>
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/cart"
                >
                  {" "}
                  <HiOutlineShoppingBag />
                  Cart
                </MenuItem>
                {/* <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/"
                >
                  {" "}
                  <BiReceipt /> My Receipt History
                </MenuItem> */}
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/transaction_History"
                >
                  {" "}
                  <HiInformationCircle /> Transaction History
                </MenuItem>
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/my-profile"
                >
                  {" "}
                  <BiReceipt /> Order History 
                </MenuItem>
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  onClick={handleClickOpen11}
                >
                  {" "}
                  <BsFillQuestionCircleFill /> Product Inquiry
                </MenuItem>
                {/* <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  onClick={handleClickOpen14}
                >
                  {" "}
                  <HiCurrencyDollar /> Currency
                </MenuItem> */}
                {/* <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/"
                >
                  <RiFeedbackFill /> Feedback
                </MenuItem> */}
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/my-profile"
                >
                  <RiLockPasswordLine /> Change Password
                </MenuItem>
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/termsCondition"
                >
                  {" "}
                  <BiReceipt /> Terms & Conditions
                </MenuItem>
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/privacypolicy"
                >
                  {" "}
                  <BiReceipt /> Privacy Policy
                </MenuItem>
                <MenuItem
                  className="menudropdown22"
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/faqs"
                >
                  {" "}
                  <BsFillQuestionCircleFill /> FAQs
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenu}
                  component={Link}
                  to="/aboutus"
                  className="menudropdown22"
                >
                  {" "}
                  <HiInformationCircle /> About Us
                </MenuItem>
                {/* <MenuItem
                  onClick={logoutHandler}
                  component={Link}
                  
                  className="menudropdown22"
                >
                  {" "}
                  <HiInformationCircle /> Logout
                </MenuItem> */}
              </Menu>
              {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                component={Link}
                to="/walletscreen"
              >
                <BiWallet style={{ color: "#005dac" }} />
              </IconButton> */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                className="walletIcon"
                component={Link}
                to="/notification"
              >
                <MdNotificationsNone />
                <span className="newNotification"></span>
              </IconButton>
              |
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                component={Link}
                to="/wishlist"
              >
                <FavoriteBorderOutlinedIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <HiOutlineShoppingBag />
              </IconButton>
              <IconButton color="inherit" aria-label="open drawer" edge="end">
                <SearchIcon />
              </IconButton>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <HiOutlineShoppingBag />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleClickOpen1}
                className={clsx(open && classes.hide)}
              >
                <AiOutlineUser />
              </IconButton>
              <SwipeableTemporaryDrawer sections={sections} />
            </Box>
          </Hidden>
        </Grid>
      </Container>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h4">Your Cart</Typography>
          <IconButton onClick={handleDrawerClose}>
            <GrClose />
          </IconButton>
        </div>
        {auth.cartList.map((data, i) => {
          return (
            <Box
              mb={1}
              p={2}
              className="cartImageBox"
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              key={i}
            >
              <figure 
              onClick={() => {
                history.push(`/product-details?id=${data.productId}`);
              }}
              >
                <img src={data && data.productImageDto &&  data.productImageDto[0].productImage} alt="" />
              </figure>
              <Box onClick={() => {
                history.push(`/product-details?id=${data.productId}`);
              }}>
                <Typography variant='h5'>{data.productName}</Typography>
                <Typography variant='h6'>
                  {data.quantity} x <span>${data.variantPrice}</span>
                </Typography>
              </Box>
              <IoMdClose
                style={{ fontSize: "14px", color: "#121313" }}
                onClick={() => removeFromCartHandler(data)}
              />
            </Box>
          );
        })}

        <Box className="subTotalBox" mt={2}>
          <Box p={2}>
            <Divider />
          </Box>

          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Subtotal</Typography>
            <Typography variant="body2">${auth.orderTotal}</Typography>
          </Box>
          <Box p={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ marginBottom: "15px" }}
              component={Link}
              to="/cart"
            >
              View Cart
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              component={Link}
              to="/order-review"
            >
              Checkout
            </Button>
          </Box>
        </Box>
      </Drawer>

      <Dialog
        open={open1}
        keepMounted
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          spacing={0}
          alignItems="stretch"
          style={{ background: "#F6F6F6" }}
        >
          <Hidden smDown>
            <Grid item xs={12} sm={6}>
              <img
                src="/images/login.png"
                alt=""
                style={{ display: "block", width: "100%" }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <Box style={{ background: "#F6F6F6", height: "100%" }}>
              {tabview === "login" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">Login</Typography>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter your phone number"
                      fullWidth
                      className="loginInput"
                      margin="normal"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                    <TextField
                      label="Password"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter your password"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                    >
                      Login
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      size="large"
                      style={{
                        margin: "15px auto 0",
                        display: "block",
                        color: "#403B58",
                      }}
                      align="center"
                      onClick={() => setTabView("forget")}
                    >
                      Forget Password?
                    </Button>

                    <Typography variant="h6" className="dflex">
                      Don't have an account?{" "}
                      <Button
                        variant="text"
                        color="primary"
                        size="large"
                        style={{ color: "#005DAC" }}
                        align="center"
                        onClick={handleClickOpen2}
                      >
                        Register
                      </Button>
                    </Typography>
                  </Box>
                </>
              ) : (
                ""
              )}

              {tabview === "forget" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">Forgot Password ?</Typography>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "20px 0 10px" }}
                      placeholder="Enter your phone number"
                      fullWidth
                      className="loginInput"
                      margin="normal"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                      onClick={() => setTabView("otp")}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
              {tabview === "otp" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">OTP Verification</Typography>
                    <Typography variant="body2">
                      Please enter the 4-digit OTP you received on your
                      registered phone number.
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "20px 0 10px", width: "60px" }}
                        className="loginInput"
                        margin="normal"
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "20px 0 10px", width: "60px" }}
                        className="loginInput"
                        margin="normal"
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "20px 0 10px", width: "60px" }}
                        className="loginInput"
                        margin="normal"
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                      <TextField
                        variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        style={{ margin: "20px 0 10px", width: "60px" }}
                        className="loginInput"
                        margin="normal"
                        InputProps={{
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                      onClick={() => setTabView("reset")}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
              {tabview === "reset" ? (
                <>
                  {" "}
                  <Box pt={7} textAlign="center">
                    <Logo />
                  </Box>
                  <Box px={3} mt={5} pb={3}>
                    <Typography variant="h4">Reset Password</Typography>
                    <TextField
                      label="New Password"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter New Password"
                      fullWidth
                      className="loginInput"
                      margin="normal"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                    <TextField
                      label="Confirm Password"
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Enter Confirm Password"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ margin: "15px 0 0" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Dialog>

      {/* Registeration field */}
      <Dialog
        open={open2}
        keepMounted
        maxWidth="md"
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ background: "#F6F6F6" }}
      >
        <Box style={{ background: "#F6F6F6" }}>
          <Box pt={7} textAlign="center">
            <Logo />
          </Box>
        </Box>
        <Grid
          container
          spacing={0}
          alignItems="stretch"
          style={{ background: "#F6F6F6" }}
        >
          <Grid item xs={12} sm={12}>
            <Box style={{ background: "#F6F6F6", height: "100%" }}>
              <Box px={3} mt={5} pb={3}>
                <Typography variant="h4">Personal Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="First Name"
                      fullWidth
                      className="loginInput"
                      margin="normal"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Last Name"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Phone Number"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.noborderselect}
                      fullWidth
                    >
                      <InputLabel htmlFor="Select_Gender">
                        Select Gender
                      </InputLabel>
                      <Select
                        style={{ margin: "8px 0" }}
                        native
                        variant="outlined"
                        fullWidth
                        className="noborderselect"
                        label="Select Gender"
                        inputProps={{
                          name: "age",
                          id: "outlined-age-native-simple",
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value={10}>Male</option>
                        <option value={20}>Female</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Password"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      classes={{ notchedOutline: classes.input }}
                      style={{ margin: "8px 0" }}
                      placeholder="Confirm Password"
                      fullWidth
                      margin="normal"
                      className="loginInput"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                    />
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  style={{ margin: "15px 0 30px" }}
                >
                  Login
                </Button>

                <Typography variant="h6" className="dflex">
                  Already have an account?{" "}
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    style={{ color: "#005DAC" }}
                    align="center"
                    onClick={handleClickOpen1}
                  >
                    Login
                  </Button>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Dialog>

      {/* delete modal start*/}
      <Dialog
        open={open10}
        onClose={handleClose10}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: "35px 25px" }}>
          <Box textAlign="center">
            <Typography
              variant="h6"
              style={{
                marginBottom: "30px",
                fontWeight: "400",
                color: "#262626",
              }}
            >
              Are you sure you want to delete this address
            </Typography>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClose={handleClose10}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClose={handleClose10}
              style={{ marginLeft: "8px" }}
            >
              Delete
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Inquiry modal start*/}
      <Dialog
        open={open11}
        onClose={handleClose11}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: "35px 25px" }}>
          <Box>
            <Typography
              variant="h6"
              style={{
                marginBottom: "30px",
                fontWeight: "400",
                color: "#262626",
                textAlign: "center",
              }}
            >
              Product Inquiry
            </Typography>
            <hr />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
            >
              <Typography>Subject</Typography>

              <Typography style={{ display: "flex" }}>
                <MailIcon style={{ color: "#005DAC" }} />
                &nbsp;&nbsp;+917978604988
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                error={isSubmit && subject === ""}
                helperText={
                  isSubmit && subject === "" && "Please enter subject"
                }
                id="standard-basic"
                label="Standard"
              />
              <Typography style={{ display: "flex" }}>
                <CallIcon style={{ color: "#005DAC" }} />
                &nbsp;&nbsp;+info@mnl.com
              </Typography>
            </Box>
            <Box pt={3}>
              <label>Description</label>
              <TextField
                variant="outlined"
                classes={{ notchedOutline: classes.input }}
                style={{ margin: "8px 0" }}
                fullWidth
                className="loginInput"
                margin="normal"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={isSubmit && description === ""}
                helperText={
                  isSubmit && description === "" && "Please enter description"
                }
              />
            </Box>
            <Box
              pt={2}
              pb={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={submitInquiryHandler}
                disabled={isUpdate}
                style={{
                  backgroundColor: "#005DAC",
                  color: "#fff",
                  width: "50%",
                }}
              >
                Submit {isUpdate && <ButtonCircularProgress />}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Currency list modal start */}

      <Dialog
        open={open14}
        onClose={handleClose14}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-width"
      >
        <DialogContent style={{ padding: "35px 25px" }}>
          <Box textAlign="center">
            <Typography
              variant="h6"
              style={{
                marginBottom: "30px",
                fontWeight: "400",
                color: "#262626",
              }}
            >
              Currency List
            </Typography>
            <hr />
            <Box className="boxcenter" className="boxcenter" pt={3}>
              <Box>
                <Typography>USD </Typography>
              </Box>
              <Box>
                <Radio
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </Box>
            </Box>

            <Box className="boxcenter">
              <Box>
                <Typography>INR </Typography>
              </Box>
              <Box>
                <Radio
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </Box>
            </Box>

            <Box className="boxcenter">
              <Box>
                <Typography>SGD </Typography>
              </Box>
              <Box>
                <Radio
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </Box>
            </Box>

            <Box className="boxcenter">
              <Box>
                <Typography>EURO </Typography>
              </Box>
              <Box>
                <Radio
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {account && (
        <Dialog
          open={ACTIVE_NETWORK !== chainId}
          className="walletdailog"
          // onClose={handleClose}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="dailogTitle">
            {"Network Error"}
          </DialogTitle>
          <DialogContent className="loginModal">
            <DialogContentText id="alert-dialog-description">
              <Box className="walletlist">
                <Typography>Please swich to {NetworkContextName}</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
