import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    // MuiDrawer:{
    //   paper:{
    //     width: "70%",
    //     padding: "30px",
    //   },
    // },
    MuiDialog: {
      paperWidthSm: {
        minWidth: "600px",
        "@media (maxWidth:767px)": {
          minWidth: "90%",
        },
      },
      paperFullScreen: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        height: "0",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
      colorSecondary: {
        height: "10px",
        backgroundColor: "#F6F6F6",
        borderRadius: "10px",
      },
      barColorSecondary: {
        backgroundColor: "#FE9A01",
        borderRadius: "3010pxpx",
      },
      colorPrimary: {
        height: "10px",
        backgroundColor: "#F6F6F6",
        borderRadius: "10px",
      },
      barColorPrimary: {
        backgroundColor: "#0D7E3A",
        borderRadius: "10px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiListItemText: {
      multiline: {
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "44px",
        color: "#000000",
      },
    },

    MuiTab: {
      wrapper: {
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "42px",
        padding: "0 20px",
        textAlign: "center",
        color: "#000000",
        "@media(max-width:991px)": {
          fontSize: "18px",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        border: "1.5px solid #FABC12",
        borderRadius: "50px",
        backgroundColor: "#fff",
        maxWidth: "fit-content",
        margin: "0 auto",
        width: "700px",
        minWidth: "700px",
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: "0 10px",
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: "start",
      },
    },
    MuiInputLabel: {
      outlined: {
        // transform: "translate(14px, 10px) scale(1)",
        // fontSize: "20px",
        // paddingLeft: "30px",
        // lineHeight: "37px",
        // color: "#fff",
        // fontWeight: "600",
      },
    },
    MuiAppBar: {
      root: { zIndex: "0" },
    },
    MuiButton: {
      root: {
        height: "55px",
        "@media (max-width: 767px)": {
          height: "38px",
        },
      },
      textPrimary: {
        height: "auto",
        padding: "0",
      },
      textSizeSmall: {
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#444444",
      },
      outlinedSizeSmall: {
        height: "38px",
        padding: "5px 15px",
      },
      outlinedSecondary: {
        height: "38px",
        padding: "5px 25px",
        borderRadius: 25,
        color: "#7c7a7a",
        border: "1px solid #7c7a7a",
        "&:hover": {
          color: "#222",
          border: "1px solid #222",
        },
      },
      containedSecondary: {
        height: "38px",
        padding: "5px 25px",
        borderRadius: 25,
        color: "#025ead",
        border: "1px solid #025ead",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#fff",
        },
      },

      contained: {
        backgroundColor: "#fff",
        border: "1px solid #BDBBBB",
        color: "#005DAC",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "18px",
        boxShadow: "none",
        padding: "14px 25px",
        borderRadius: 5,
        "&:hover": {
          boxShadow: "none",
        },
        "@media (max-width: 767px)": {
          fontSize: "17px",
          lineHeight: "25px",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#005DAC",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#005DAC",
      dark: "#202831",
      light: "#e2e3fa",
    },
    secondary: {
      main: "#F2EDED",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
